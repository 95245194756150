import { render, staticRenderFns } from "./FaqMay2023PriceUpdate.vue?vue&type=template&id=207c8faa"
import script from "./FaqMay2023PriceUpdate.vue?vue&type=script&lang=ts"
export * from "./FaqMay2023PriceUpdate.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports